<template lang="pug">
div(v-if='Blog')
  //-PC
  div.d-none.d-md-flex
    v-divider.primario.divider_inicial
    v-container(fluid).pa-0.ma-0
      v-row.ml-5.mt-12
          h3.grey--text.lighten-2.font-weight-light Palabras claves
      v-row.mr-5.ml-5.mt-3
          v-slide-group.mt-2
              v-slide-item(v-for='{nombre, orden, id}, i in Registro_de_etiquetas' :key='i' 
                v-slot:default='{ active, toggle }')
                  v-chip( elevation='0' :color='Etiqueta_filtro == id ? "color_etiqueta" : "grey"' 
                  dark @click='seleccionar_etiqueta(id)').chip.mr-2
                      span.white--text.pa-3 {{nombre}}
          v-spacer
          v-text-field(placeholder='Buscar' color='#999999' outlined 
                      append-icon='fas fa-search' v-model='texto_buscador' 
                      style='border-radius: 0px 0 0 0px !important').mr-0
      v-row(v-if='registro_de_entradas.length>0').mt-6.pt-6.pb-5.pl-4.pr-1
        Novedad_item(v-for='entrada, i in registro_de_entradas' :key='i' :entrada='entrada').pl-4.pr-8.pb-8
      v-row(v-if='registro_de_entradas.length>0').mt-4.mb-12.pr-6.pl-6.ml-12.mr-1
        v-container.align-center.justify-center
          v-pagination(v-model="página" color='primario' :length="1" prev-icon="mdi-menu-left" next-icon="mdi-menu-right")
      v-row(v-else).mt-1.pr-6.pl-6.mb-12
        v-container(fluid align='center' justify='center')
          v-row(align='center' justify='center').py-5
              v-icon(x-large ) fas fa-exclamation-triangle
          v-row(align='center' justify='center')
            h2.piqr--text.text-center ¡Ups! No se ha encontrado la entrada.
          v-row(align='center' justify='center')
            strong Verifique su escritura o inténtelo de nuevo.
  //-Móvil
  v-container.d-md-none
    v-row.ml-2.mt-6
      h3.grey--text.lighten-2.font-weight-light Palabras claves
    v-row.mr-1.ml-1.mt-3
      v-slide-group.mt-2
          v-slide-item(v-for='{nombre, orden, id}, i in Registro_de_etiquetas' :key='i' 
            v-slot:default='{ active, toggle }')
              v-chip( elevation='0' :color='Etiqueta_filtro == id ? "color_etiqueta" : "grey"' 
              dark @click='seleccionar_etiqueta(id)').chip.mr-2
                  span.white--text.pa-3 {{nombre}}
      v-spacer
      v-text-field(placeholder='Buscar' color='#999999' outlined 
        append-icon='fas fa-search' v-model='texto_buscador' 
        style='border-radius: 0px 0 0 0px !important').mr-0.mt-7
    v-container.mt-1(v-if='registro_de_entradas.length>0').pr-1.pl-1.pt-4
      Novedad_item(v-for='entrada, i in registro_de_entradas' :key='i' :entrada='entrada').pt-4
    v-row(v-if='registro_de_entradas.length>0').mt-5.mb-12.align-center.justify-center
      v-pagination(v-model="página" color='primario' :length="1" prev-icon="mdi-menu-left" next-icon="mdi-menu-right")
    v-row.mt-1.pr-6.pl-6(v-else)
      v-container(fluid align='center' justify='center')
        v-row(align='center' justify='center').py-5
            v-icon(x-large ) fas fa-exclamation-triangle
        v-row(align='center' justify='center')
          h2.piqr--text.text-center.align-center ¡Ups! No se ha encontrado la entrada.
        v-row(align='center' justify='center').mt-3
          strong.text-center.align-center  Verifique su escritura o inténtelo de nuevo.
    v-row
      v-divider.negro.mt-6.mb-7
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    data: ()=>({
        página: 1,
        texto_buscador: '',
    }),
    computed: {
      registro_de_entradas(){
        return this.$store.getters['Blog/registro_de_entradas']({filtro: this.texto_buscador, etiqueta_filtro: this.Etiqueta_filtro})
      },
      ...mapState({
        Etiqueta_filtro: ({Etiquetas})=>Etiquetas.etiqueta_filtro,
      }),
    },
    components: {
        Novedad_item: ()=>import('./novedad_item'),
    },
    methods: {
      ...mapMutations({
        alterar_etiqueta: 'Etiquetas/alterar_etiqueta',
      }),
      ...mapActions({
        seleccionar_etiqueta: 'Etiquetas/seleccionar_etiqueta',
      }),
    },
}
</script>
